import PropTypes from "prop-types";
import React from "react";
import { graphql } from "gatsby";
import Wrapper from "../Wrapper";
import { Flex, Box, Heading, Button } from "rebass/styled-components";
import Link from "../../UI/Link";
import Cards from "../../UI/Cards";

const RelatedContent = ({ data, lang }) => {
  if (data.related) {
    data.related = data.related.map(item => {
      item.href = item.href.indexOf("/") < 0 ? "/" + item.href : item.href;
      return item;
    });
    return (
      <Wrapper data={data} py={[0, 0, 0]}>
        <Box
          pt={[10, 10, 10]}
          pb={[9, 7, 7]}
          sx={{
            borderWidth: "1px 0 0 0",
            borderColor: "grayLight",
            borderStyle: "solid"
          }}
        >
          {data.title && (
            <Heading as="h2" variant="h2" mb={[2, 1, 1]}>
              {data.title}
            </Heading>
          )}
          <Cards data={data.related} related="true" lang={lang} />
        </Box>
      </Wrapper>
    );
  } else {
    return <></>;
  }
};

RelatedContent.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.any,
    related: PropTypes.any
  })
};

export default RelatedContent;
