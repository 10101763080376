import PropTypes from "prop-types";
import React from "react";
import { graphql } from "gatsby";
import Wrapper from "../Wrapper";
import { Box, Button } from "rebass/styled-components";
import Link from "../../UI/Link";

const FilesList = ({ data }) => {
    let fileURL = "";
return (
    <Wrapper data={data}>
        <Box
        sx={{
            "& > div:not(:first-child)": {
                mt: 3
            }
        }}>
            {data.ctas.map((cta, i) => (
                fileURL = cta.href.publicURL ? cta.href.publicURL : cta.href,
                <Box key={i}>
                    <Link href={fileURL}>
                        <Button variant="linkDownload">{cta.label}</Button>
                    </Link>
                </Box>
            ))}
        </Box>
    </Wrapper>
);
};

FilesList.propTypes = {
data: PropTypes.shape({
    ctas: PropTypes.any,
})
};
export default FilesList;