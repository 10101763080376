import { graphql } from "gatsby";
import FilesList  from "./FilesList"

export default FilesList;

export const query = graphql`
  fragment FilesListBlock on MarkdownRemarkFrontmatterBlockscollection {
    id
    ctas {
        label
        href
    }
  }
`;
