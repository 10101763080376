import PropTypes from "prop-types";
import React from "react";
import Section from "../UI/Section";
import Container from "../UI/Container";
import { Parallax } from "react-parallax";
import { Heading } from "rebass/styled-components";

const ParallaxWrapper = ({ parallaxActived, bgImage, children }) => {
  if (parallaxActived) {
    return (
      <Parallax bgImage={bgImage} bgImageAlt="back" strength={1000}>
        {children}
      </Parallax>
    );
  }
  return <>{children}</>;
};

ParallaxWrapper.propTypes = {
  bgImage: PropTypes.any,
  children: PropTypes.any,
  parallaxActived: PropTypes.any
};

const Wrapper = ({ data, children, containerProps, sx, ...props }) => {
  containerProps = containerProps || {};
  const blockTitle = 
    data.blockSettings
    ? data.blockSettings.showTitle && data.blockSettings.blockname
      ? data.blockSettings.blockname
      : false
    : false || false;
  const bgImg = 
    data.blockSettings
    ? data.blockSettings.bgImg
      ? data.blockSettings.bgImg
      : false
    : false || false;
  const bgImgUrl = bgImg
    ? typeof bgImg === "string"
      ? bgImg
      : bgImg.childImageSharp.fluid.src
    : "";
  const sectionProps = {};
  sx = sx || {};
  const fluidContainer = data.blockSettings ? data.blockSettings.containerFluid : false;
  const maxContainer = data.blockSettings ? data.blockSettings.maxContainer : false;
  const parallaxActived = data.blockSettings ? data.blockSettings.parallax : false;
  const fullHeightActived = data.blockSettings ? data.blockSettings.fullHeight : false;

  if (data.blockSettings) {
    sectionProps.id = data.blockSettings.id;
    sectionProps.bg = data.blockSettings.bg;
    sectionProps.color = data.blockSettings.color;
    if (data.blockSettings.align) {
      sectionProps.textAlign = data.blockSettings.align;
    }
    if (data.blockSettings.padding) {
      sectionProps.pb = data.blockSettings.padding.bottom;
      sectionProps.pt = data.blockSettings.padding.top;
    }
    sectionProps.sx = sx;
    if (bgImg) {
      sectionProps.sx = {
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundImage: `url(${bgImgUrl})`,
        ...sx
      };
    }
  }
  if (fullHeightActived) {
    containerProps.minHeight = "100vh";
  }

  const finalProps = {
    ...sectionProps,
    ...props
  };
  if (parallaxActived) {
    finalProps.bg = "";
    finalProps.sx.backgroundImage = "";
    finalProps.sx.background = "";
    finalProps.sx.bg = "";
  }

  return (
    <ParallaxWrapper parallaxActived={parallaxActived} bgImage={bgImgUrl}>
      <Section {...finalProps}>
        <Container fluid={fluidContainer} {...containerProps} maxContainer={maxContainer}>
          {blockTitle !== false && (
            <Heading as="h2" variant="blockTitle">{blockTitle}</Heading>
          )}
          {children}
        </Container>
      </Section>
    </ParallaxWrapper>
  );
};

Wrapper.propTypes = {
  children: PropTypes.any,
  containerProps: PropTypes.shape({
    minHeight: PropTypes.any
  }),
  data: PropTypes.shape({
    blockSettings: PropTypes.shape({
      align: PropTypes.any,
      bg: PropTypes.any,
      bgImg: PropTypes.any,
      blockname: PropTypes.string,
      color: PropTypes.any,
      containerFluid: PropTypes.any,
      fullHeight: PropTypes.any,
      id: PropTypes.any,
      maxContainer: PropTypes.any,
      className: PropTypes.any,
      padding: PropTypes.shape({
        bottom: PropTypes.any,
        top: PropTypes.any
      }),
      parallax: PropTypes.any,
      showTitle: PropTypes.any
    })
  }),
  sx: PropTypes.object
};

export default Wrapper;
