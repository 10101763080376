import { graphql } from "gatsby";
import RelatedContent  from "./RelatedContent"

export default RelatedContent;

export const query = graphql`
  fragment RelatedContentBlock on MarkdownRemarkFrontmatterBlockscollection {
    title
    related {
      title
      href
      image {
        publicURL
        childImageSharp {
          fluid(maxWidth: 500, maxHeight: 200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
`;
