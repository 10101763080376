import PropTypes from "prop-types";
import React from "react";
import Wrapper from "../Wrapper";
import { graphql } from "gatsby";
import Markdown from "../../UI/Markdown";
import CustomImage from "../../UI/CustomImage";
import { Box, Heading, Flex, Button } from "rebass/styled-components";
import Link from "../../UI/Link";

const ImgMarkupTwoCol = ({ data }) => {
  return (
    <Wrapper data={data}>
      {data.title && (
        <Heading as="h2" variant="h2">
          {data.title}
        </Heading>
      )}
      <Flex
        variant="grid"
        alignItems="center"
        flex="1"
        flexDirection={
          (data.textPosition == "left" && ["column-reverse", "column-reverse", "row"]) ||
          (data.textPosition == "right" && ["column-reverse", "column-reverse", "row-reverse"])
        }
      >
        <Box
          width={[1, 1, 1 / 2]}
          variant="gridItem"
        >
          <Box pt={[4, 4, 0]}>
            <Box
            sx={{
              "& > div > ul:last-child": {
                mb: 0,
                "li:last-child": {
                  mb: 0
                }
              },
              "p:last-child": {
                  mb:0
              }
            }}>
              <Markdown>{data.content}</Markdown>
            </Box>
            {data.cta && (
              <Box mt={6} mr={9}>
                <Link href={data.cta.href}>
                    <Button variant={data.cta.download ? "linkDownload" : "linkText"}>
                    {data.cta.label}
                    </Button>
                </Link>
              </Box>
            )}
          </Box>
        </Box>
        <Box width={[1, 1, 1 / 2]} variant="gridItem" >
          <Box
          sx={{
            ".gatsby-image-wrapper": {
              maxHeight: ["45vw","50vw","auto"]
            }
          }}>
            <CustomImage img={data.image} ml="auto" mr="auto" />
          </Box>
        </Box>
      </Flex>
    </Wrapper>
  );
};

ImgMarkupTwoCol.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.any,
    image: PropTypes.any,
    content: PropTypes.any,
    cta: PropTypes.any,
    textPosition: PropTypes.any
  })
};
export default ImgMarkupTwoCol;
