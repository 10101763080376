import PropTypes from "prop-types";
import React from "react";
import Grid from "./Grid";
import Markdown from "./Markdown";
import CustomImage from "./CustomImage";
import { Box, Heading, Flex, Text } from "rebass/styled-components";
import Fontello from "./Fontello";
import Link from "./Link";

const Cards = ({ data, related, lang }) => {
  return (
    <Grid
    childWidth={related ? [1, 1, 1 / 2] : [1, 1 / 2, 1 / 3]}
    sx={{
      "& > div": {
        py:[2/"2",6/"2",6/"2"]
      }
    }}>
      {data.map((card, i) => (
        <Box
        key={"card-" + i}
        height="100%">
          <Box variant="card" bg="gray" height="100%">
            {card.image && (
              <Box
              sx={{
                ".gatsby-image-wrapper": {
                  maxHeight: related ? ["180px"] : ["170px","inherit","inherit"]
                }
              }}>
                <CustomImage img={card.image} ml="auto" mr="auto" />
              </Box>
            )}
            <Box variant="cardTitle">
              <Heading as="h2">
                <Link href={lang == "en" ? "/en"+card.href : card.href}>
                  {card.title}
                </Link>
              </Heading>
            </Box>
          </Box>
        </Box>
      ))}
    </Grid>
  );
};

export default Cards;
